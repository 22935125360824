import { useMemo } from "react";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

import { cn } from "@/lib/utils";
import Icon from "./Icon";
import { CalculatorDropdown, CalculatorInput, InputWrapper } from "./Inputs";

import DottedLineImage from "./dotted-line.svg";
import { CalculatorModeString, SimpleCalculatorFormData } from "./types";
import { FormField } from "@/components/ui/form";
import { numericOnChange } from "./helpers";

import { Select, SelectProps, usePinning } from "@/ui-lib/select";
import { DBBackedPinAdapter } from "@/components/pin-adapter";

export const PortDropdown = ({
  label,
  id,
  value,
  setValue,
  ports,
  isLoading,
  error,
  className,
  pinProps,
}: {
  label: string;

  value: string | null;
  setValue: (value: string | null) => void;

  id?: string;

  ports: Array<{ value: string; label: string }>;

  isLoading?: boolean;

  error?: string;
  className?: string;

  tabIndex?: number;

  pinProps?: Pick<
    SelectProps,
    "pinEnabled" | "pinOption" | "pinnedOptions" | "context"
  >;
}) => {
  return (
    <InputWrapper
      id={id}
      isRequired
      label={label}
      error={error}
      labelClassName="md:hidden"
      className={className}
    >
      <Select
        multiple={false}
        {...pinProps}
        options={ports}
        rounded
        placeholder={label}
        color="lightGrey"
        mode="dark"
        loadingAnimation="pulse"
        value={value ?? null}
        onChange={setValue}
        loading={isLoading}
        hasError={!!error}
      />
    </InputWrapper>
  );
};

interface VoyagePortInputProps {
  label: string;

  isLast?: boolean;

  mode: CalculatorModeString;

  ports: Array<{ value: string; label: string }>;
  terms: Array<{ value: string; label: string }>;

  isLoading?: boolean;
}

export function VoyagePortInput<T extends FieldValues>({
  label,
  isLast,
  mode,
  ports,
  terms,
  isLoading,

  form,
  portField,
  cadenceField,
  termsField,
  draftField,
  portDAField,

  tabIndex,
}: VoyagePortInputProps & {
  form: UseFormReturn<T>;

  portField: FieldPath<T>;
  cadenceField: FieldPath<T>;
  termsField: FieldPath<T>;
  draftField: FieldPath<T>;
  portDAField: FieldPath<T>;

  tabIndex?: number;
}) {
  const portValue = form.watch(portField);

  const pinning = usePinning("port", DBBackedPinAdapter);

  return (
    <div className="mb-0.5 relative">
      <div
        className={cn("absolute left-0 top-2.5 bg-frGrey-1200", {
          "bottom-[-5px]": isLast,
        })}
      >
        <Icon
          icon={isLast ? "MapPinFilled" : "Circle"}
          className="text-frGrey-300 h-6 w-6"
        />
      </div>

      <div className="pl-9">
        <div className="rounded-3xl p-3 px-2 pb-1 bg-frGrey-990">
          <FormField
            control={form.control}
            name={portField}
            render={({ field, fieldState: { error } }) => (
              <PortDropdown
                label={label}
                id={portField}
                value={field.value ?? null}
                setValue={(value) => {
                  field.onChange(value ?? null);
                }}
                ports={ports}
                isLoading={isLoading || pinning.pending}
                error={error?.message}
                tabIndex={tabIndex}
                /*pinProps={{
                  ...pinning,
                  pinEnabled: true,
                }}*/
              />
            )}
          />

          {portValue && mode === CalculatorModeString.Advanced ? (
            <div className="grid gap-2 grid-cols-2 mt-2 md:grid-cols-4">
              <FormField
                control={form.control}
                name={cadenceField}
                render={({ field, fieldState: { error } }) => (
                  <CalculatorInput
                    id={cadenceField}
                    type="number"
                    label="Cadence"
                    placeholder="Cadence"
                    suffix="mt/ day"
                    inputProps={{
                      ...field,

                      min: 1,
                    }}
                    onChange={numericOnChange(field.onChange)}
                    error={error?.message}
                    tabIndex={tabIndex}
                  />
                )}
              />

              <FormField
                control={form.control}
                name={termsField}
                render={({ field, fieldState: { error } }) => (
                  <CalculatorDropdown
                    value={
                      terms.find((term) => term.value === field.value) ?? null
                    }
                    setValue={(value) => {
                      field.onChange(value?.value ?? null);
                    }}
                    choices={terms}
                    label="Terms"
                    placeholder="Terms"
                    emptyText="No results found."
                    error={error?.message}
                    tabIndex={tabIndex}
                  />
                )}
              />

              <FormField
                control={form.control}
                name={draftField}
                render={({ field, fieldState: { error } }) => (
                  <CalculatorInput
                    id={draftField}
                    type="number"
                    label="Draft"
                    placeholder="Draft"
                    suffix="m"
                    inputProps={{
                      ...field,
                    }}
                    onChange={numericOnChange(field.onChange)}
                    error={error?.message}
                    tabIndex={tabIndex}
                  />
                )}
              />

              <FormField
                control={form.control}
                name={portDAField}
                render={({ field, fieldState: { error } }) => (
                  <CalculatorInput
                    id={portDAField}
                    type="number"
                    label="Port DA"
                    placeholder="Port DA"
                    suffix="$"
                    inputProps={{
                      ...field,

                      min: 0,
                    }}
                    onChange={numericOnChange(field.onChange)}
                    error={error?.message}
                    tabIndex={tabIndex}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

interface VoyagePortInputGroupProps {
  mode: CalculatorModeString;

  form: UseFormReturn<SimpleCalculatorFormData>;

  multiple?: boolean;

  ports: Array<{ unlocode: string; displayName: string }>;

  terms: Array<{
    code: string;
    description: string;
    timeFactor: number;
  }>;

  isLoading?: boolean;

  tabIndex?: number;
}

export default function VoyagePortInputGroup({
  mode,

  terms,
  ports,
  isLoading,

  tabIndex,

  form,
}: VoyagePortInputGroupProps) {
  const portsOptions = useMemo(
    () =>
      ports.map((port) => ({
        value: port.unlocode,
        label: `${port.displayName} (${port.unlocode})`,
      })),
    [ports]
  );

  const termsOptions = useMemo(
    () =>
      terms.map((term) => ({
        label: `${term.code} (${term.description} ${term.timeFactor})`,
        value: term.code,
      })),
    [terms]
  );

  return (
    <div className="flex flex-col gap-4 px-5 py-4 pr-4 bg-frGrey-1200 relative md:pr-0">
      <div
        className="absolute top-5 bottom-4 left-5 pointer-none w-6 mt-1.5"
        style={{
          background: `url(${DottedLineImage.src}) repeat-y center top`,
        }}
      />

      <VoyagePortInput
        label="Load Port"
        mode={mode}
        ports={portsOptions}
        terms={termsOptions}
        isLoading={isLoading}
        form={form}
        portField="startPort.port"
        cadenceField="startPort.cadence"
        termsField="startPort.terms"
        draftField="startPort.draft"
        portDAField="startPort.portDA"
        tabIndex={tabIndex}
      />
      <VoyagePortInput
        label="Discharge Port"
        mode={mode}
        isLast
        ports={portsOptions}
        terms={termsOptions}
        isLoading={isLoading}
        form={form}
        portField="endPort.port"
        cadenceField="endPort.cadence"
        termsField="endPort.terms"
        draftField="endPort.draft"
        portDAField="endPort.portDA"
        tabIndex={tabIndex}
      />
    </div>
  );
}
