import { SearchOptionsT } from './search';

export type OptionKey = string;

export type SelectColor = 'lightGrey' | 'grey';
export type SelectColorMode = 'light' | 'dark';

export type SelectOptionBase = {
  label: string;
  value: OptionKey;

  disabled?: boolean; // if true, the option is not selectable

  // internal use only
  $groupTitle?: string | undefined;
  $empty?: boolean;
  $lastPinned?: boolean;
  $isPinRow?: boolean;

  // used for sorting the search results by the original order when using ResultOrdering.DATA
  $index?: number;
  // used for adjusting padding left of option that are in a group
  $level?: number;
};

export type SelectOption<T extends Record<string, any> = any> =
  SelectOptionBase & T;

export interface SelectGroup<T extends Record<string, any> = any> {
  label: string;
  options: (SelectOption<T> | SelectGroup<T>)[]; // options in the group
}

export enum ResultOrdering {
  SCORE = 'score', // order by match score
  DATA = 'data', // order by the options order
}

export enum PinMode {
  Duplicate = 'duplicate',
  Single = 'single',
}

export interface BaseSelectProps {
  required?: boolean; // default: false
  loading?: boolean; // default: false
  disabled?: boolean; // default: false

  // Set to True if the options are grouped
  grouped?: boolean; // default: false

  // Set to True to disable clearing button
  noClear?: boolean; // default: false

  // Label/placeholder
  label?: string;
  placeholder?: string;
  emptyMultiLabel?: string;

  // styling
  mode?: SelectColorMode;
  color?: SelectColor;
  rounded?: boolean; // default: false

  // class names
  inputWrapperClassName?: string;

  // Animation configuration
  loadingAnimation?: 'dots' | 'pulse';

  // Error state
  hasError?: boolean;
  errorText?: string;

  // Used to identify the select box, used for saving pinned items
  // should be set and unique for each select box type when pinning is enabled
  context?: string;

  // search logic
  searchOrdering?: ResultOrdering;

  // Pinning (favorites) params
  pinEnabled?: boolean; // default: true
  pinnedOptions?: OptionKey[];
  pinMode?: PinMode; // default: PinMode.Duplicate
  pinOption?: (context: string, optionKey: OptionKey) => void;
}

export interface SelectProps<T extends Record<string, any> = any>
  extends BaseSelectProps {
  options: SelectOption<T>[] | SelectGroup<T>[];
  value?: OptionKey | null;

  multiple?: false;

  onChange?: (value: OptionKey | null) => void;

  searchOptions?: SearchOptionsT<T>;
}

export interface MultiSelectProps<T extends Record<string, any> = any>
  extends BaseSelectProps {
  options: SelectOption<T>[] | SelectGroup<T>[];

  multiple?: true;

  value?: OptionKey[]; // TODO: if this is undefined the form select is uncontrolled, otherwise its controlled
  onChange?: (value: OptionKey[] | null) => void;

  searchOptions?: SearchOptionsT<T>;
}

export const isSelectGroupArray = <T extends Record<string, any>>(
  items: SelectOption<T>[] | SelectGroup<T>[]
): items is SelectGroup<T>[] =>
  (items as SelectGroup<T>[])[0]?.options !== undefined;

export const isSelectGroup = <T extends Record<string, any>>(
  item: SelectOption<T> | SelectGroup<T>
): item is SelectGroup<T> => (item as SelectGroup<T>).options !== undefined;
