import { cva } from "class-variance-authority";

import { RemoveIcon } from "./icons";
import { SelectColor, SelectColorMode } from "./select.types";

export const Pill = ({
  text,
  onRemove,

  mode = "light",
  color = "lightGrey",
}: {
  text: string;
  onRemove: () => void;

  mode?: SelectColorMode;
  color?: SelectColor;
}) => (
  <span
    onClick={(e) => e.stopPropagation()}
    className={pillVariants({
      mode,
      color,
    })}
  >
    {text}
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        onRemove();
      }}
      className={buttonVariants({
        mode,
        color,
      })}
    >
      <span className="sr-only">Remove</span>
      {/* TODO use from icon lib */}
      <RemoveIcon />
    </button>
  </span>
);

const buttonVariants = cva(
  "cursor-pointer flex-shrink-0 ml-0.5 pl-1 pr-2.5 -mr-2.5 inline-flex focus:outline-none focus:ring-none -my-1 py-1",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
    },

    compoundVariants: [
      {
        color: "lightGrey",
        mode: "light",
        className: "text-frGrey-550 hover:text-frGrey-500",
      },
      {
        color: "lightGrey",
        mode: "dark",
        className: "text-frGrey-300 hover:text-frGrey-400",
      },
      {
        color: "grey",
        mode: "light",
        className: "text-frGrey-550 hover:text-frGrey-500",
      },
      {
        color: "grey",
        mode: "dark",
        className: "text-frGrey-300 hover:text-frGrey-400",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
    },
  }
);

const pillVariants = cva(
  "text-xs font-medium px-2.5 py-0.5 rounded pointer-events-auto cursor-default border",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
    },

    compoundVariants: [
      {
        color: "lightGrey",
        mode: "light",
        className: "bg-frGrey-300 text-frGrey-550 border-frGrey-350",
      },
      {
        color: "lightGrey",
        mode: "dark",
        className: "bg-frGrey-550 text-frGrey-300 border-frGrey-525",
      },
      {
        color: "grey",
        mode: "light",
        className: "bg-frGrey-300 text-frGrey-550 border-frGrey-350",
      },
      {
        color: "grey",
        mode: "dark",
        className: "bg-frGrey-550 text-frGrey-300 border-frGrey-525",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
    },
  }
);
