import { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionKey } from '../select.types';
import { PinAdapter } from './pin.types';

export const usePinning = (context: string, adapter: PinAdapter) => {
  const [pinnedOptions, setPinnedOptions] = useState<string[]>([]);

  const [pending, setIsPending] = useState(false);

  const loadPinnedOptions = useCallback(async () => {
    setIsPending(true);

    const savedItems = await adapter.get(context);

    setPinnedOptions(savedItems);

    setIsPending(false);
  }, [context, setIsPending]);

  useEffect(() => {
    void loadPinnedOptions();
  }, [loadPinnedOptions]);

  return useMemo(
    () => ({
      context,
      pending,

      pinOption: (context: string, optionKey: OptionKey) => {
        const index = pinnedOptions.indexOf(optionKey);

        const newPinnedOptions =
          index !== -1
            ? pinnedOptions.filter((key) => key !== optionKey)
            : [...pinnedOptions, optionKey];

        setPinnedOptions(newPinnedOptions);

        void adapter.save(context, newPinnedOptions);
      },
      pinnedOptions: pinnedOptions,
    }),
    [pinnedOptions, context, pending]
  );
};
