import { KeyOption, matchSorter, rankings } from 'match-sorter';
import { ResultOrdering } from './select.types';

export interface SearchOptionsT<T extends Record<string, any>> {
  keys: Array<keyof T>;

  // Params for match-sorter
  threshold: (typeof rankings)[keyof typeof rankings];
}

export const defaultSearchOptions: SearchOptionsT<{
  label: string;
}> = {
  keys: ['label'],

  // Params for match-sorter
  threshold: rankings.MATCHES,
};

export function fuzzySearch<T extends Record<string, any>>(
  rows: T[],
  keys: Array<keyof T>,
  query: string,
  threshold?: SearchOptionsT<T>['threshold'],
  ordering?: ResultOrdering
) {
  if (!query || !query.length) {
    return rows;
  }

  const terms = query.split(' ');

  if (!terms) {
    return rows;
  }

  // reduceRight will mean sorting is done by score for the _first_ entered word.
  const final = terms.reduceRight(
    (results, term) =>
      matchSorter<T>(results, term, {
        keys: keys as KeyOption<T>[],
        threshold,
      }),
    rows
  );

  if (ordering === ResultOrdering.DATA) {
    final.sort((a, b) => a.$index - b.$index);
  }

  return final;
}
