import {
  useState,
  useMemo,
  useCallback,
  useRef,
  useLayoutEffect,
  forwardRef,
  useEffect,
} from "react";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import classNames from "classnames";
import { CircleFilledIcon, Dot1, Dot2, Dot3 } from "./icons";
import { defaultSearchOptions, fuzzySearch } from "./search";
import {
  SelectProps,
  MultiSelectProps,
  isSelectGroupArray,
  isSelectGroup,
  SelectOption,
  PinMode,
  SelectGroup,
} from "./select.types";
import { Pill } from "./pill";
import { cva } from "class-variance-authority";
import {
  IconCheck,
  IconChevronDown,
  IconCircle,
  IconStar,
  IconStarFilled,
  IconX,
} from "@tabler/icons-react";

const clearVariants = cva("h-4 w-4", {
  variants: {
    color: {
      lightGrey: "",
      grey: "",
    },
    mode: {
      dark: "",
      light: "",
    },
    disabled: {
      true: "cursor-not-allowed",
      false: "",
    },
  },

  compoundVariants: [
    {
      color: "lightGrey",
      mode: "light",
      className: "text-frGrey-400 hover:text-frGrey-350",
    },
    {
      color: "lightGrey",
      mode: "dark",
      className: "text-frGrey-300 hover:text-frGrey-400",
    },
    {
      disabled: true,
      mode: "dark",
      color: "lightGrey",
      className: "text-frGrey-500",
    },
    {
      disabled: true,
      mode: "light",
      color: "lightGrey",
      className: "opacity-70",
    },
    {
      color: "grey",
      mode: "light",
      className: "text-frGrey-400 hover:text-frGrey-350",
    },
    {
      color: "grey",
      mode: "dark",
      className:
        "text-neutral-200 hover:text-neutral-50 group-hover:text-neutral-50",
    },
    {
      disabled: true,
      mode: "dark",
      color: "grey",
      className: "text-neutral-600",
    },
    {
      disabled: true,
      mode: "light",
      color: "grey",
      className: "opacity-70",
    },
  ],

  defaultVariants: {
    color: "lightGrey",
    mode: "light",
    disabled: false,
  },
});

const caretVariants = cva(
  "transition-transform duration-300 ease-in-out w-4 h-4",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
      error: {
        true: "",
        false: "",
      },
      disabled: {
        true: "cursor-not-allowed",
        false: "",
      },
    },

    compoundVariants: [
      {
        color: "lightGrey",
        mode: "light",
        error: false,
        className: "text-frGrey-400",
      },
      {
        color: "lightGrey",
        mode: "dark",
        error: false,
        className: "text-frGrey-300",
      },
      {
        color: "grey",
        mode: "light",
        error: false,
        className: "text-frGrey-400",
      },
      {
        color: "grey",
        mode: "dark",
        error: false,
        className: "text-neutral-200 group-hover:text-neutral-50",
      },
      {
        error: true,
        className: "text-error",
      },
      {
        disabled: true,
        error: false,
        mode: "dark",
        color: "lightGrey",
        className: "text-neutral-500",
      },
      {
        disabled: true,
        error: false,
        mode: "dark",
        color: "grey",
        className: "text-neutral-500 group-hover:text-neutral-500",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
      error: false,
    },
  }
);

const inputWrapperVariants = cva(
  "relative w-full cursor-default overflow-hidden text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 " +
    "block w-full border text-base text-dark-grey shadow-sm focus:outline-none focus:ring-2 focus:ring-accent-light leading-none",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },

      error: {
        true: "",
        false: "",
      },

      disabled: {
        true: "cursor-not-allowed",
        false: "",
      },

      rounded: {
        true: "rounded-full",
        false: "rounded-lg",
      },

      multiple: {
        true: "",
        false: "",
      },
    },

    compoundVariants: [
      {
        mode: "light",
        color: "lightGrey",
        error: false,
        className: "border-frGrey-300",
      },
      {
        color: "lightGrey",
        error: true,

        className: "border-error focus:border-error focus:ring-error",
      },
      {
        mode: "dark",
        color: "lightGrey",
        error: false,
        className: "border-frGrey-640 bg-frGrey-790 hover:bg-frGrey-640",
      },
      {
        mode: "dark",
        color: "lightGrey",
        error: true,
        className: "bg-frGrey-790 hover:bg-frGrey-640",
      },

      {
        mode: "light",
        color: "grey",
        error: false,
        className: "border-frGrey-300",
      },
      {
        color: "grey",
        error: true,

        className: "border-error focus:border-error focus:ring-error",
      },
      {
        mode: "dark",
        color: "grey",
        error: false,
        className:
          "border-neutral-600 bg-neutral-900 hover:border-neutral-400 group-data-[open]:border-neutral-400",
      },
      {
        mode: "dark",
        color: "grey",
        error: false,
        disabled: true,
        className: "hover:border-neutral-600",
      },
      {
        mode: "dark",
        color: "grey",
        error: true,
        disabled: false,
        className: "bg-frGrey-985 hover:bg-frGrey-790", // TODO: update when error state is designed
      },
      {
        mode: "dark",
        color: "grey",
        error: true,
        disabled: true,
        className: "",
      },

      {
        rounded: true,
        multiple: true,
        className: "pl-3",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
      error: false,
      disabled: false,
      rounded: false,
      multiple: false,
    },
  }
);

const inputVariants = cva(
  "w-full bg-transparent border-none py-2 pl-3 pr-10 text-xs leading-4 font-normal font-roboto focus:ring-0 focus:outline-none",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
      error: {
        true: "",
        false: "",
      },
      disabled: {
        true: "cursor-not-allowed",
        false: "",
      },
    },

    compoundVariants: [
      {
        mode: "light",
        color: "lightGrey",
        error: false,
        className: "text-frGrey-600 placeholder-frGrey-400",
      },
      {
        mode: "dark",
        color: "lightGrey",
        error: false,
        className: "text-frGrey-300 placeholder-frGrey-300",
      },
      {
        mode: "light",
        color: "grey",
        error: false,
        className: "text-frGrey-600 placeholder-frGrey-400",
      },
      {
        mode: "dark",
        color: "grey",
        error: false,
        className:
          "text-neutral-200 placeholder-neutral-200 " +
          "hover:placeholder-neutral-50 group-data-[open]:placeholder-neutral-50 " +
          "hover:text-neutral-50 group-data-[open]:text-neutral-50",
      },
      {
        error: true,
        disabled: false,
        className: "text-error placeholder-error",
      },
      {
        error: true,
        disabled: true,
        className: "text-error placeholder-error/50",
      },
      {
        disabled: true,
        mode: "dark",
        color: "lightGrey",
        className:
          "text-neutral-600 placeholder-neutral-600 hover:placeholder-neutral-600 hover:text-neutral-600",
      },
      {
        disabled: true,
        mode: "dark",
        color: "grey",
        className:
          "text-neutral-600 placeholder-neutral-600 hover:placeholder-neutral-600 hover:text-neutral-600",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
      disabled: false,
    },
  }
);

const multiEmptyLabelVariants = cva("text-sm h-[22px]", {
  variants: {
    color: {
      lightGrey: "",
      grey: "",
    },
    mode: {
      dark: "",
      light: "",
    },
  },
  compoundVariants: [
    {
      color: "lightGrey",
      mode: "light",
      className: "text-gray-300",
    },
    {
      color: "lightGrey",
      mode: "dark",
      className: "text-gray-400",
    },
    {
      color: "grey",
      mode: "light",
      className: "text-gray-300",
    },
    {
      color: "grey",
      mode: "dark",
      className: "text-gray-400",
    },
  ],
  defaultVariants: {
    color: "lightGrey",
    mode: "light",
  },
});

const optionsWrapperVariants = cva(
  "w-[var(--input-width)] mt-1 p-2 !max-h-64 no-hover:data-[open]:!max-h-screen no-hover:data-[open]:h-full overflow-y-auto border overflow-x-hidden empty:invisible z-50 rounded text-base shadow-sm ring-0 focus:outline-none sm:text-sm",

  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
    },

    compoundVariants: [
      {
        color: "lightGrey",
        mode: "light",
        className: "bg-white border-frGrey-300",
      },
      {
        color: "lightGrey",
        mode: "dark",
        className: "bg-frGrey-640 border-frGrey-640",
      },
      {
        color: "grey",
        mode: "light",
        className: "bg-white border-frGrey-300",
      },
      {
        color: "grey",
        mode: "dark",
        className: "bg-neutral-900 border-neutral-400",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
    },
  }
);

const optionVariants = cva(
  "group w-full cursor-default rounded select-none p-2 pr-4 data-[disabled]:opacity-50 flex items-center justify-start",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "text-frGrey-300 data-[focus]:bg-neutral-800 data-[selected]:bg-neutral-700",
        light: "data-[focus]:bg-frGrey-300 data-[selected]:bg-frGrey-300",
      },

      lastPinned: {
        true: "border-b",
        false: "",
      },
      isPinned: {
        true: "",
        false: "",
      },
    },

    compoundVariants: [
      {
        color: "lightGrey",
        mode: "dark",
        lastPinned: true,
        className: "border-frGrey-500",
      },
      {
        color: "grey",
        mode: "dark",
        lastPinned: true,
        className: "border-frGrey-500",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
      lastPinned: false,
      isPinned: false,
    },
  }
);

function parseOptions<T extends Record<string, any>>(
  theItems: SelectOption<T>[],
  options: (SelectOption<T> | SelectGroup<T>)[],
  i: number,
  level: number = 0
) {
  for (const group of options) {
    if (isSelectGroup(group)) {
      theItems.push({
        label: group.label,
        value: `group-${group.label}`,
        disabled: true,

        $groupTitle: group.label,
        $level: level,
        $index: i,
      } as SelectOption<T>);

      i += 1;

      i = parseOptions(theItems, group.options, i, level + 1);
    } else {
      theItems.push({
        ...group,

        $index: i,
        $level: level,
      });
      i += 1;
    }
  }

  return i;
}

const getInitial = <T extends Record<string, any>>(
  items: SelectOption<T>[],
  value: string | string[] | null | undefined
) => {
  if (!value || value?.length === 0) {
    return [];
  }

  const valArr = Array.isArray(value) ? value : [value];

  return valArr
    .map((val) => {
      return items.find((item) => item.value === val) ?? null;
    })
    .filter((x) => !!x) as SelectOption<T>[];
};

export default function Select<T extends Record<string, any>>({
  label,
  options,
  inputWrapperClassName,
  placeholder = "Select an option",
  emptyMultiLabel = "-",
  multiple,

  disabled,
  loading,

  grouped,

  loadingAnimation = "dots",

  searchOrdering,

  hasError,
  errorText,

  noClear,
  rounded,

  color = "grey",
  mode = "light",

  context,

  pinEnabled,
  pinOption,
  pinnedOptions,
  pinMode = PinMode.Duplicate,

  searchOptions,

  value: ctrlValue,
  onChange,
}: SelectProps<T> | MultiSelectProps<T>) {
  const searchConfig = searchOptions ?? defaultSearchOptions;

  const [query, setQuery] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const filteredItems = useMemo(() => {
    let theItems: SelectOption<T>[] =
      grouped || isSelectGroupArray<T>(options) ? [] : options;

    if (grouped && isSelectGroupArray(options)) {
      let i = 0;

      // When items are grouped, we need to add `group` property to the first item of each group.

      i = parseOptions(theItems, options, i, 0);
    }

    if (pinnedOptions && pinEnabled && pinnedOptions.length > 0) {
      const pinned = theItems
        .filter((item) => pinnedOptions.includes(item.value))
        .map((item) => structuredClone(item));

      pinned[pinned.length - 1].$lastPinned = true;
      for (const item of pinned) {
        item.$isPinRow = true;
      }

      const rest =
        pinMode === PinMode.Duplicate
          ? theItems
          : theItems.filter((item) => !pinnedOptions.includes(item.value));

      theItems = [...pinned, ...rest];
    }

    const results =
      (!query
        ? theItems
        : fuzzySearch(
            theItems ?? [],
            searchConfig.keys,
            query,
            searchConfig.threshold,
            searchOrdering
          )) ?? [];

    if (results.length === 0 && !!query) {
      return [
        { value: "", label: "Nothing found", $empty: true } as SelectOption<T>,
      ];
    }

    if (results.length === 0) {
      return [
        {
          value: "",
          label: "No options available",
          $empty: true,
        } as SelectOption<T>,
      ];
    }

    return results;
  }, [
    options,
    query,
    grouped,
    searchConfig.keys,
    searchConfig.threshold,
    searchOrdering,
    pinEnabled,
    pinnedOptions,
    pinMode,
  ]);

  const [selected, setSelected] = useState<SelectOption<T>[]>(
    getInitial(filteredItems, ctrlValue)
  );

  const clearSelect = useCallback(() => {
    setSelected([]);
    setQuery("");
    onChange?.(null);
  }, [setSelected, setQuery, onChange]);

  const value: SelectOption<T> | Array<SelectOption<T>> | null = ((multiple
    ? selected
    : selected?.[0]) ?? null) as
    | SelectOption<T>
    | Array<SelectOption<T>>
    | null;

  const onChangeHandler = useCallback(
    (value: any) => {
      if (multiple) {
        setSelected(value);
        onChange?.(value.map((x: SelectOption<T>) => x.value));
      } else {
        setSelected(value ? [value] : []);
        onChange?.(value?.value ?? null);
      }
    },
    [setSelected, multiple, onChange]
  );

  const valRef = useRef<string | string[] | null>(null);

  useEffect(() => {
    if (ctrlValue && valRef.current !== ctrlValue) {
      if (ctrlValue) {
        const valArr = Array.isArray(ctrlValue) ? ctrlValue : [ctrlValue];

        const foundItems = valArr
          .map((val) => {
            return filteredItems.find((item) => item.value === val) ?? null;
          })
          .filter((x) => !!x) as SelectOption<T>[];

        if (foundItems.length === valArr.length) {
          valRef.current = ctrlValue;

          setSelected(foundItems);
        }
      } else {
        setSelected([]);
        valRef.current = ctrlValue;
      }
    }
  }, [ctrlValue, filteredItems]);

  const forceOpen = useCallback(() => {
    inputRef.current?.focus?.();
  }, []);

  const [pillsHeight, setPillsHeight] = useState<number>(0);

  const pillsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (pillsRef.current) {
      setPillsHeight(pillsRef.current.clientHeight);
    }
  }, [selected]);

  return (
    <div className="copmer-select">
      <div
        className={classNames("relative", {
          "animate-pulse": loading && loadingAnimation === "pulse",
        })}
      >
        <Combobox
          value={value as any}
          multiple={multiple}
          virtual={{
            options: filteredItems,
            disabled: (item) => !!(item?.disabled || item?.$empty),
          }}
          disabled={disabled}
          onChange={onChangeHandler}
          onClose={() => setQuery("")}
          immediate
        >
          <div className="relative group no-hover:data-[open]:fixed no-hover:data-[open]:left-0 no-hover:data-[open]:right-0 no-hover:data-[open]:top-0">
            <div
              className={classNames(
                inputWrapperVariants({
                  color,
                  mode,
                  error: !!hasError,
                  disabled: !!disabled,
                  rounded: !!rounded,
                  multiple: !!multiple,
                }),
                inputWrapperClassName
              )}
            >
              <ComboboxInput
                aria-label={label}
                displayValue={(item: SelectOption<T> | null) =>
                  item?.label ?? ""
                }
                onChange={(event) => setQuery(event.target.value)}
                className={inputVariants({
                  color,
                  mode,
                  error: !!hasError,
                  disabled: !!disabled,
                })}
                onFocus={(e) => {
                  e.target.select();
                }}
                ref={inputRef}
                autoComplete="off"
                placeholder={placeholder}
              />
              {multiple && (
                <div
                  className="pb-2 pl-3 pr-16 flex gap-1 flex-row items-center cursor-pointer flex-wrap"
                  onClick={forceOpen}
                  ref={pillsRef}
                >
                  {selected.length > 0 ? (
                    selected.map((item) => (
                      <Pill
                        color={color}
                        mode={mode}
                        key={item.value}
                        text={item.label ?? "N/A"}
                        onRemove={() => {
                          setSelected((cur) =>
                            cur.filter((i) => i.value !== item.value)
                          );
                          onChange?.(
                            selected
                              ?.map((x) => x.value)
                              .filter((x) => x !== item.value)
                          );
                        }}
                      />
                    ))
                  ) : (
                    <span
                      className={multiEmptyLabelVariants({
                        color,
                        mode,
                      })}
                    >
                      {emptyMultiLabel}
                    </span>
                  )}
                </div>
              )}
            </div>

            {noClear || (multiple ? !value?.length : !value) ? null : (
              <button
                className="absolute right-10 inset-y-0 z-[99999] flex items-center"
                type="button"
                onClick={clearSelect}
              >
                <span className="sr-only">Clear</span>

                <IconX
                  className={clearVariants({
                    mode,
                    color,
                    disabled: !!disabled,
                  })}
                />
              </button>
            )}

            <ComboboxButton className="absolute top-0 right-0 bottom-0 flex items-center justify-center pr-3 [&>svg]:data-[open]:rotate-180">
              <IconChevronDown
                className={caretVariants({
                  mode,
                  color,
                  error: !!hasError,
                  disabled: !!disabled,
                })}
              />
            </ComboboxButton>

            <ComboboxOptions
              anchor="bottom"
              as={OptionsWrapper}
              className={optionsWrapperVariants({
                color,
                mode,
              })}
              style={{
                transform: pillsHeight
                  ? `translateY(${pillsHeight}px)`
                  : undefined,
              }}
            >
              {({ option: item }) => {
                return (
                  <ComboboxOption
                    value={item}
                    className={classNames(
                      optionVariants({
                        color,
                        mode,
                        lastPinned: !!item.$lastPinned,
                        isPinned: !!item.$isPinRow,
                      }),
                      `pl-${item.$level + 2}`
                    )}
                  >
                    {!item.$groupTitle && !item.$empty && multiple ? (
                      <div className="mr-2 h-4 w-4 flex items-center justify-center cursor-pointer">
                        <IconCheck className="selected-icon invisible group-data-[selected]:visible h-4 w-4" />
                      </div>
                    ) : null}
                    {!item.$groupTitle && !item.$empty && !multiple ? (
                      <div className="mr-2 h-4 w-4 flex items-center justify-center cursor-pointer">
                        <IconCircle className="selected-icon block group-data-[selected]:hidden h-4 w-4" />
                        <CircleFilledIcon className="selected-icon hidden group-data-[selected]:block h-4 w-4" />
                      </div>
                    ) : null}

                    <span>{item.label}</span>

                    <span className="flex-grow" />

                    {pinEnabled && !item.$groupTitle ? (
                      <button
                        type="button"
                        className="cursor-pointer group/pin"
                        onClick={() => {
                          if (context) {
                            pinOption?.(context, item.value);
                          }
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <IconStarFilled
                          className={classNames("ml-1 h-4 w-4", {
                            "hidden group-hover/pin:block":
                              !pinnedOptions?.includes(item.value),
                            "block group-hover/pin:hidden":
                              pinnedOptions?.includes(item.value),
                          })}
                        />
                        <IconStar
                          className={classNames("ml-1 h-4 w-4", {
                            "hidden group-hover/pin:block":
                              pinnedOptions?.includes(item.value),
                            "block group-hover/pin:hidden":
                              !pinnedOptions?.includes(item.value),
                          })}
                        />
                      </button>
                    ) : null}
                  </ComboboxOption>
                );
              }}
            </ComboboxOptions>

            {loading && (
              <div className="absolute left-0 top-0 bottom-0 right-0 flex items-center justify-end text-gray-400 cursor-wait">
                {loadingAnimation === "dots" && (
                  <>
                    <Dot1 className="absolute right-10 animate-pulsefirst" />
                    <Dot2 className="absolute right-10 animate-pulsesecond" />
                    <Dot3 className="absolute right-10 animate-pulsethird" />
                  </>
                )}
              </div>
            )}
          </div>
        </Combobox>

        {hasError && errorText ? (
          <div className="mt-1 pl-3 pr-3 text-sm text-error">{errorText}</div>
        ) : null}
      </div>
    </div>
  );
}

const OptionsWrapper = forwardRef(({ children, ...props }: any, ref) => {
  return (
    <div className="copmer-select">
      <div {...props} ref={ref}>
        {children}
      </div>
    </div>
  );
});

OptionsWrapper.displayName = "OptionsWrapper";
